<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组别编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="运单组别编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="装货地">
				<el-cascader class="el_input" v-model="form.case_cities" @change="ser_para_case_cities_change" placeholder="装货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="到货地">
				<el-cascader class="el_input" v-model="form.aim_cities" @change="ser_para_aim_cities_change" placeholder="到货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="运单编号/货物名称/类型" width="220px">
					<template slot-scope="scope">
						<div class="tab_line_item">(编号){{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">(名称){{scope.row.cargo_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="重量/体积" width="118px">
					<template slot-scope="scope">
						<div class="tab_line_item">(重量){{scope.row.cargo_weight==0?"未设置":scope.row.cargo_weight+"吨"}}</div>
						<div class="tab_line_item">(体积){{scope.row.cargo_volume==0?"未设置":scope.row.cargo_volume+"方"}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省市区) {{scope.row.case_prov}} / {{scope.row.case_city}} / {{scope.row.case_county}}</div>
						<div class="tab_line_item">(详细) {{scope.row.case_addr?scope.row.case_addr:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省市区) {{scope.row.aim_prov}} / {{scope.row.aim_city}} / {{scope.row.aim_county}}</div>
						<div class="tab_line_item">(详细) {{scope.row.aim_addr?scope.row.aim_addr:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/服务费" width="135px">
					<template slot-scope="scope">
						<div class="tab_line_item">(总运费) {{scope.row.freight_total}}元</div>
						<div class="tab_line_item">(已支付) {{scope.row.freight_payed_total}}元</div>
						<div class="tab_line_item">(服务费) {{scope.row.extra_service_charge_total}}元</div>
						<div class="tab_line_item">(已支付) {{scope.row.extra_service_charge_payed_total}}元</div>
					</template>
				</el-table-column>
				<el-table-column label="收款人信息" width="134px">
					<template slot-scope="scope">
						<div class="tab_line_item">(姓名) {{scope.row.truck_owner_info.name}}</div>
						<div class="tab_line_item">(电话) {{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">(车牌) {{scope.row.truck_plate_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="时间信息" width="172px">
					<template slot-scope="scope">
						<div class="tab_line_item">(接单){{scope.row.creat_time_text}}</div>
						<div class="tab_line_item">(发车){{scope.row.start_time_text}}</div>
						<div class="tab_line_item">(到货){{scope.row.arrived_time_text}}</div>
						<div class="tab_line_item">(驳回){{scope.row.invoiced_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="80">
					<template slot-scope="scope">
						<el-button class="btn_left" @click="invoice_apply(scope.row)" size="mini" type="text">重新申请</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
</template>

<script>
	import {regionData,CodeToText} from 'element-china-area-data'
	export default {
		data() {
			return {

				//搜索条件参数
				ser_para:{
					cities:regionData,//省市区数据
				},

				//搜索条件
				form: {				
					truck_cargo_num:'',//货源编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//收款人手机号
					driver_tel:'',//司机手机号
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区

					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
			}
		},
		created() {
			
			//读取货源
			this.get_page_data()
		},
		methods: {
			
			//申请开票
			invoice_apply(item){

				//暂时给个弹窗 以后有空改成开票预览
				this.$my.other.confirm({
					content:"运费:"+item.freight_total+"元,服务费:"+item.extra_service_charge_total+"元",
					confirm:()=>{
						
						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_after_time',
								ctr:'tord_invoice_apply_by_shipper',
								truck_tord_num:item.truck_tord_num,
								invoice_status:item.invoice_status
							},
							callback:(data)=>{
								
								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},
			
			//清空查询条件
			ser_para_clear(){
				this.form={
					truck_cargo_num:'',//货源编号
					truck_owner_tel:'',//收款人手机号
					driver_tel:'',//司机手机号
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县
				}
				this.get_page_data()
			},

			//发货地省市县选择器
			ser_para_case_cities_change(event){
				this.form.case_prov=CodeToText[event[0]];
				this.form.case_city=CodeToText[event[1]];
				this.form.case_county=CodeToText[event[2]];
			},

			//到货地省市县选择器
			ser_para_aim_cities_change(event){
				this.form.aim_prov=CodeToText[event[0]];
				this.form.aim_city=CodeToText[event[1]];
				this.form.aim_county=CodeToText[event[2]];
			},

			//搜索
			cargo_ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_after_time',
						ctr:'tord_invoice_revoke_list_by_shipper',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//发车时间
							if(item.start_time>0){//已发车
								item.start_time_text=this.$my.other.totime(item.start_time);
							}else item.start_time_text="未发车";
							
							//到货时间
							if(item.arrived_time>0){//已到货
								item.arrived_time_text=this.$my.other.totime(item.arrived_time);
							}else item.arrived_time_text="未到货";
							
							//开票时间
							if(item.invoiced_time>0){
								item.invoiced_time_text=this.$my.other.totime(item.invoiced_time);
							}else item.invoiced_time_text="未到货";

							//装货时间
							if(item.case_date_start==0&&item.case_date_end!=0){//只有止点

								item.case_date=this.$my.other.totime(item.case_date_end)+"以前";
							}else if(item.case_date_start!=0&&item.case_date_end==0){//只有起点

								item.case_date=this.$my.other.totime(item.case_date_start)+"以后";
							}else if(item.case_date_start!=0&&item.case_date_end!=0){//都有

								item.case_date=this.$my.other.totime(item.case_date_start)+" ~ "+this.$my.other.totime(item.case_date_end);
							}else if(item.case_date_start==0&&item.case_date_end==0){//都没有

								item.case_date="未设置";
							}

							//卸货时间
							if(item.aim_date_start==0&&item.aim_date_end!=0){//只有止点

								item.aim_date=this.$my.other.totime(item.aim_date_end)+"以前";
							}else if(item.aim_date_start!=0&&item.aim_date_end==0){//只有起点

								item.aim_date=this.$my.other.totime(item.aim_date_start)+"以后";
							}else if(item.aim_date_start!=0&&item.aim_date_end!=0){//都有

								item.aim_date=this.$my.other.totime(item.aim_date_start)+" ~ "+this.$my.other.totime(item.aim_date_end);
							}else if(item.aim_date_start==0&&item.aim_date_end==0){//都没有
								
								item.aim_date="未设置";
							}
							
							//运单状态
							switch(item.status){
								case '1':item.status_text="待发车";break;
								case '2':item.status_text="运输中";break;
								case '3':item.status_text="已到货";break;
							}
							
							//开票状态
							switch(item.invoice_status){
								case '1':item.invoice_status_text="未申请";break;
								case '2':item.invoice_status_text="已申请";break;
								case '3':item.invoice_status_text="已开出";break;
								case '4':item.invoice_status_text="被驳回";break;
							}

							//运费支付状态
							switch(item.freight_payed_status){
								case '1':item.freight_payed_status_text="未支付";break;
								case '2':item.freight_payed_status_text="支付部分";break;
								case '3':item.freight_payed_status_text="支付完毕";break;
							}

							//服务费支付状态
							switch(item.service_charge_payed_status){
								case '1':item.service_charge_payed_status_text="未支付";break;
								case '2':item.service_charge_payed_status_text="支付部分";break;
								case '3':item.service_charge_payed_status_text="支付完毕";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>